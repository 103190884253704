import React from 'react'
import config from 'global-config'
import { call, put, takeLatest, select, all, delay } from 'redux-saga/effects'
import request, {
  getAccessToken,
  setAccessToken,
  setStoreCode,
} from 'utils/request'
import {
  setCurrentUser,
  checkUserIsMembership,
  checkMembershipIsExpired,
  getCurrentUserFullName,
} from 'utils/validate'
import * as actions from './actions'
import * as constants from './constants'
import * as selectors from './selectors'
import * as checkoutActions from 'containers/Checkout/actions'
import * as homeActions from 'containers/Home/actions'
import * as landerActions from 'containers/Landers/actions'
import {
  showToastSuccessCustom,
  showUpdate,
  showError,
} from 'utils/notification'
import * as navigation from 'utils/navigation'
import { messages, SCREEN } from 'global-constant'
import { isMobile } from 'react-device-detect'
import { cleanEmpty } from 'utils/helpers/removeEmptyValues'

import { get, filter, find, isEmpty, isEqual, cloneDeep } from 'lodash'
import * as modalActions from '../Modals/actions'
import * as membershipActions from 'containers/Membership/actions'
import NotifySuccessNotification from 'component/product/NotifySuccessNotification'
import { directoryCountries } from 'constant/directoryCountries'
import { getCurrentUser } from 'utils/validate'
import { clearAuthData, getDeviceId } from 'utils/localStorage'
import { getMessaging, deleteToken } from 'firebase/messaging'
import { mapDatastoreToAddress } from 'utils/helper'
import { createAddress, updateAddress } from 'containers/Account/actions'
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
import { getMembershipExpiration } from 'containers/Membership/actions'
import { push } from 'connected-react-router'
import { identifyUser } from 'utils/tiktokEvents'
import { setStoreView } from 'utils/request'
import { getStoreViewCode } from 'utils/products'
import { DEFAULT_STORE_VIEW } from 'containers/Landers/constants'
import * as authActions from 'containers/Authentication/actions'
import { setNodeTokenApi, setExpiryApi, setUserEmailApi } from 'api/utils'
import { setNodeToken, setExpiry } from 'utils/req'
import URL from 'constant/urls'
import {
  getProductRecommendation1,
  getProductRecommendation2,
} from 'containers/Checkout/screens/CheckoutCart/actions'
import { ctSetUserProfile } from 'utils/clevertap/ctUserProfile'
import { trackEvent as trackEvents } from 'utils/tracking'
import {
  logUserEngagementEvent,
  trackingEvent,
  trackEvent,
} from 'utils/firebase'
// import { trackEvent } from 'utils/tracking'
import dayjs from 'dayjs'
import { ctUpdateUserProfile } from 'utils/clevertap/ctUserProfile'
import { ctEvent, ctEventPush } from 'utils/clevertap/ctEvent'

// call request token api
function* onLoadSideBar(action) {
  const requestURL = `${config.apiUrl}/landers-megamenu-by-session/all`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    let menu = cloneDeep(res)
    menu.forEach((val) => {
      if (val.category_id !== 0 && val.category_id !== null) {
        const { category_id, name, url, flag, icon, status } = val
        const showAll = {
          name: `Shop All ${name}`,
          category_id,
          url,
          flag,
          icon,
          status,
        }
        val.children.unshift(showAll)
      }
    })

    yield put(actions.loadSideBarSuccess(menu))
  } catch (err) {
    yield put(actions.loadSideBarFailed(err))
  }
}

function* onLoadSubMenuTop(action) {
  const requestURL = `${config.apiUrl}/landers-megamenu/menu-top`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadSubMenuTopSuccess(res))
  } catch (err) {
    yield put(actions.loadSubMenuTopFailed(err))
  }
}

// call request create cart guest
function* onCheckLocation(action) {
  const requestURL = `${config.apiUrl}/check-location`

  try {
    const cartId = yield call(request, requestURL, {
      method: 'POST',
    })

    yield put(actions.checkLocationSuccess(cartId))
  } catch (err) {
    yield put(actions.checkLocationFailed(err))
  }
}

function* onLoadGlobalConfig() {
  const requestURL = `${config.apiUrl}/landersrestapi/globalconfig`
  const requestURLMobile = `${config.apiUrl}/baseapi/globalconfig`

  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    //end point use to get guest_cart_qty_limit
    const resMobile = yield call(request, requestURLMobile, {
      method: 'GET',
    })

    res['guest_cart_qty_limit'] = resMobile?.guest_cart_qty_limit

    yield put(actions.loadGlobalConfigSuccess(res))
    yield put(actions.getStoreMapping())
  } catch (err) {
    yield put(actions.loadGlobalConfigFailed())
  }
}

// call request get user info
export function* onGetUserInfo(action) {
  const {
    needValidateMembership,
    needValidateDefaultShipping,
    membershipRequest,
    needUpdateID,
    method,
    isFromSignUp,
    isSubscribed,
  } = action
  let requestURL = `${config.apiUrl}/customers/me?needValidate=true`

  try {
    const userInfo = yield call(request, requestURL, {
      method: 'GET',
    })
    setCurrentUser(userInfo)

    //Logout customer account if customer support session's ends
    if (
      localStorage.getItem('is_supported_account') &&
      !sessionStorage.getItem('is_customer_support')
    ) {
      yield put(landerActions.logout({}))
      return
    }

    //Check if customer support and supported account
    if (sessionStorage.getItem('is_customer_support')) {
      localStorage.setItem('is_supported_account', 1)
    } else if (
      userInfo &&
      userInfo.extension_attributes &&
      userInfo.extension_attributes.is_customer_support
    ) {
      sessionStorage.setItem('is_customer_support', 1)
      sessionStorage.setItem('support_token', getAccessToken())
    }

    yield put(actions.setYellowAIPayload(userInfo))
    yield put(actions.getUserInfoSuccess(userInfo))
    if (needValidateMembership) {
      const isMemberShip = checkUserIsMembership()
      const isMemberShipExpired = checkMembershipIsExpired()
      if (!isMemberShip) {
        yield put(modalActions.showRequestAddMembershipModal())
      } else if (isMemberShipExpired) {
        yield put(modalActions.showModalInformMembershipIsExpired())
      }
    }

    if (membershipRequest) {
      const membershipInfo = get(
        userInfo,
        'extension_attributes.membership_info',
        {},
      )
      yield put(
        membershipActions.renewMembership({
          renew_price: get(membershipInfo, 'renew_price'),
          code: get(membershipInfo, 'code'),
          name: get(membershipInfo, 'name'),
          package: get(membershipInfo, 'package'),
          is_expired_renew: true,
        }),
      )
    }

    if (needValidateDefaultShipping) {
      const currentLocation = yield select((state) =>
        selectors.makeSelectCurrentLocation()(state),
      )
      const validAddresses = filter(get(userInfo, 'addresses', []), (address) =>
        get(
          address,
          'extension_attributes.address_validation_data.source_code',
        ),
      )

      if (!currentLocation.barangay && !currentLocation.id) {
        const defaultAddress = find(
          validAddresses,
          (address) => address.default_shipping,
        )

        if (defaultAddress) {
          yield put(actions.checkLocationSuccess(defaultAddress))
          yield put(homeActions.loadProducts())
        }
      }
    }

    yield put(getMembershipExpiration(userInfo))

    if (needUpdateID) {
      const analytics = getAnalytics()
      if (!!userInfo?.id) {
        setUserId(analytics, `${userInfo.id}`)
        setUserProperties(analytics, {
          user_id: `${userInfo.id}`,
          user_id_dimension: `${userInfo.id}`,
        })
        ctSetUserProfile(userInfo)
        yield put(actions.updateCTUserProfile(userInfo))
        if (isFromSignUp) {
          ctEventPush(ctEvent.signup, {
            authentication_method: method ? method : '',
            receive_exclusive_offer: isSubscribed,
          })
        }
        const { type = '', code = '' } = get(
          userInfo,
          'extension_attributes.membership_info',
          {},
        )
        const loginMethod =
          method === '' || method === undefined ? 'email/password' : method
        const isCustomerHasProfile = Object.keys(userInfo).length > 0
        let ctLoginPayload = {
          authentication_method: isCustomerHasProfile ? loginMethod : '',
          linked_membership: isCustomerHasProfile ? !!code : false,
          linked_membership_type:
            isCustomerHasProfile && type !== null
              ? String(type).toLocaleLowerCase()
              : '',
          login_status: isCustomerHasProfile ? true : false,
        }
        ctEventPush(ctEvent.login, cleanEmpty(ctLoginPayload))
        identifyUser(userInfo)
        if (method) {
          yield put(
            authActions.setLoginType({
              date: '',
              method: method,
            }),
          )
        }
      } else {
        setUserId(analytics, null)
        setUserProperties(analytics, {
          user_id: null,
          user_id_dimension: null,
        })
      }
    }

    if (userInfo?.extension_attributes?.has_temp_pass === true) {
      yield put(push('/customer/account/edit?changePassword=true'))
      return false
    }

    return userInfo
  } catch (err) {
    yield put(actions.getUserInfoFailed(err))
    return null
  }
}

function* onLoadProvince(action) {
  const requestURL = `${config.apiUrl}/directory/countries/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadProvinceSuccess(get(res, 'available_regions')))
    yield put(actions.loadCountryNameSuccess(get(res, 'full_name_english', '')))
  } catch (err) {
    // ignore the CORS issue that occurs very often
    // by hardcoding with the list provice
    yield put(
      actions.loadProvinceSuccess(get(directoryCountries, 'available_regions')),
    )
    yield put(
      actions.loadCountryNameSuccess(
        get(directoryCountries, 'full_name_english', ''),
      ),
    )
  }
}

function* onLoadCity(action) {
  const requestURL = `${config.apiUrl}/directory/region/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadCitySuccess(get(res, 'available_cities')))
  } catch (err) {
    yield put(actions.loadCityFailed(err))
  }
}

function* onLoadBarangay(action) {
  const requestURL = `${config.apiUrl}/directory/city/${action.payload.id}`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    yield put(actions.loadBarangaySuccess(get(res, 'available_townships')))
  } catch (err) {
    yield put(actions.loadBarangayFailed(err))
  }
}

function* onLoadFooter(action) {
  const requestURL = `${config.apiUrl}/ldJsonCmsBlock/json-footer`
  try {
    yield put({ type: constants.LOAD_FOOTER_REQUEST })
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(actions.loadFooterSuccess(res))
  } catch (err) {
    yield put(actions.loadFooterFailed(err))
  }
}

function* onLoadAllCms(action) {
  const requestURL = `${config.apiUrl}/all-cms`
  try {
    const res = yield call(request, requestURL, { method: 'GET' })
    yield put(actions.loadAllCmsSuccess(res))
  } catch (err) {
    yield put(actions.loadAllCmsFailed(err))
  }
}

// call request create cart guest
function* onGetSubscribeProduct(action) {
  try {
    let requestURL = `${config.apiUrl}/ld-productnotifyme/notify`
    const res = yield call(request, requestURL, {
      method: 'POST',
      body: JSON.stringify(action.payload),
    })
    yield put(actions.getSubscribeProductSuccess(res))
    const user = yield select((state) =>
      selectors.makeSelectCurrentUser()(state),
    )
    if (!res.error) {
      if (res.message === 'You are already subscribed to this product.') {
        showUpdate(messages.alreadySubscribed)
      } else {
        showToastSuccessCustom(<NotifySuccessNotification email={user.email} />)
      }
    } else {
      showError('Subscribe product failed, try again or contact support')
    }
  } catch (err) {
    console.log('err:', err)
    showError('Subscribe product failed, try again or contact support')
    yield put(actions.getSubscribeProductFailed(err))
  }
}

function* onUpdateShippingAddress() {
  const userInfo = yield select((state) =>
    selectors.makeSelectCurrentUser()(state),
  )
  const currentLocation = yield select((state) =>
    selectors.makeSelectCurrentLocation()(state),
  )

  const pickupAddress = find(get(userInfo, 'addresses', []), (address) =>
    get(address, 'extension_attributes.store_pickup'),
  )

  if (currentLocation?.datastore) {
    let address = mapDatastoreToAddress(currentLocation, userInfo)

    if (!isEmpty(pickupAddress)) {
      yield put(
        updateAddress({ address: { ...address, id: pickupAddress.id } }, true),
      )
    } else {
      yield put(createAddress({ address }, true))
    }
    yield put(checkoutActions.setShippingAddress(address))
    yield put(actions.checkLocationSuccess(address))
  } else {
    const shippingAddress = find(get(userInfo, 'addresses', []), {
      default_shipping: true,
    })
    yield put(checkoutActions.setShippingAddress(shippingAddress))
    yield put(actions.checkLocationSuccess(shippingAddress))
  }
}

function* onLoadCategoryMenu() {
  try {
    const url = `${config.apiUrl}/landers-megamenu/vertical-menu-left`
    const res = yield call(request, url)
    yield put({
      type: constants.LOAD_CATEGORY_MENU_LEFT_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({
      type: constants.LOAD_CATEGORY_MENU_LEFT_FAILURE,
    })
    showError(error.message || 'Failed to get category menu!')
  }
}

function* onLoadSideAds() {
  try {
    const url = `${config.apiUrl}/landers-bannerslider/right1`
    const res = yield call(request, url)
    yield put({
      type: constants.LOAD_SIDEADS_SUCCESS,
      payload: res,
    })
  } catch (error) {
    yield put({
      type: constants.LOAD_SIDEADS_FAILURE,
    })
    showError(error.message || 'Failed to get banner slider!')
  }
}

async function deleteFCMToken() {
  try {
    const dispatch = navigation.getDispatch()
    const userId = getCurrentUser()?.id
    const accessToken = getAccessToken()
    const messaging = getMessaging()

    if (!!userId && !!accessToken) {
      dispatch(landerActions.deleteFCMToken({ userId, accessToken }))
      await deleteToken(messaging)
    }
  } catch (err) {
    console.log('err', err)
  }
}

function* onLogout(action) {
  const isSupportedAccount = action.payload && action.payload.supported_account

  if (isSupportedAccount) {
    yield put(actions.showLoader())
    setAccessToken(sessionStorage.getItem('support_token'))
    navigation.navigate(SCREEN.HOME)

    yield delay(500)
    yield put(actions.hideLoader())
    window.location.reload()
    return
  }

  const analytics = getAnalytics()
  deleteFCMToken()
  setAccessToken('')
  setUserId(analytics, null)
  setUserProperties(analytics, {
    user_id: null,
    user_id_dimension: null,
  })

  setStoreCode('')
  setStoreView(DEFAULT_STORE_VIEW)
  setCurrentUser({})
  setExpiry('')
  setNodeTokenApi('')
  setExpiryApi('')
  setUserEmailApi('')
  setNodeToken('')
  yield all([
    put(actions.clearCurrentLocation()),
    put(checkoutActions.setShippingAddress({})),
    put(checkoutActions.getLastOrderOOSSuccess('')),
    put(checkoutActions.setCancellationOption('')),
    put(checkoutActions.setDefaultCancellationOption('')),
    put(checkoutActions.createCart()),
    put(checkoutActions.applyCouponSuccess('')),
    put(
      authActions.setLambdaAccessToken({
        access_token: '',
        expires_in: '',
      }),
    ),
    put(checkoutActions.clearBizRebate()),
    put(membershipActions.verifyExtensionMembership()),
    put(homeActions.loadProducts()),
    put(actions.showSideBar(false)),
    put(actions.setYellowAIPayload()),
  ])

  const isCustomerSupport = sessionStorage.getItem('is_customer_support')

  if (isCustomerSupport) {
    sessionStorage.removeItem('is_customer_support')
    yield delay(200)
    window.location.reload()
  } else {
    navigation.navigate(SCREEN.HOME)
  }
}

function* onLoadStoreAddresses(action) {
  const requestURL = `${config.apiUrl}/baseapi/globalconfig`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })

    yield put(
      actions.loadStoreAddressesSuccess(get(res, 'store_pickup_addresses', [])),
    )

    yield put(
      actions.loadSpecialCategoryIDSuccess(
        get(res, 'special_product_category', 0),
      ),
    )

    yield put(actions.loadPreferredBranches(get(res, 'preferred_branches', [])))

    const configOOS = {
      oos_cancellation: get(res, 'oos_cancellation', []),
      oos_note: get(res, 'oos_footer', ''),
      oos_description: get(res, 'oos_header', ''),
      oos_default: get(res, 'oos_default'),
    }

    yield put(actions.loadOutOfStockConfig(configOOS))
    const footerConfig = get(res, 'footer_config', [])
    yield put(actions.loadFooterConfig(footerConfig))
    yield put(
      actions.loadDeliverySlotVerbiage(get(res, 'delivery_slot_verbiage', [])),
    )

    yield put(
      actions.loadSearchAndAutoSuggest(
        get(res, 'unbxd_search_and_auto_suggest[0]', {}),
      ),
    )
  } catch (err) {
    yield put(actions.loadStoreAddressesFailed(err))
  }
}

function* onUpdateFCMToken(action) {
  const requestURL = `${config.nmsUrl}/nms/tokens`
  const bodyData = {
    deviceID: getDeviceId(),
    platform: isMobile ? 'msite' : 'web',
    token: action.payload.fcmToken,
    userID: `${getCurrentUser().id}`,
  }
  try {
    yield call(request, requestURL, {
      method: 'PUT',
      body: JSON.stringify(bodyData),
    })
  } catch (err) {
    console.log('err', err)
  }
}
function* onDeleteFCMToken(action) {
  const requestURL = `${config.nmsUrl}/nms/tokens`

  const bodyData = {
    deviceID: getDeviceId(),
    platform: isMobile ? 'msite' : 'web',
    userID: `${action.payload.userId}`,
  }

  try {
    yield call(request, requestURL, {
      method: 'DELETE',
      headers: {
        PlatformName: 'LANDERS',
        Authorization: `Bearer ${action.payload.accessToken}`,
      },
      body: JSON.stringify(bodyData),
    })
  } catch (err) {
    console.log('err', err)
  }
}

function* onGetStoreMapping() {
  try {
    const requestURL = `${config.apiUrl}/store-source-mapping`

    const response = yield call(request, requestURL)

    yield put(actions.setStoreMappingSuccess(response))
  } catch (err) {
    console.log('err', err)
  }
}

function* onRehydrateStoreViewCode(action) {
  const storeCode = action.payload

  const storeViewSources = yield select((state) =>
    selectors.makeSelectStoreViewSources()(state),
  )

  setStoreView(getStoreViewCode(storeCode, storeViewSources))
}

// eslint-disable-next-line
function* onSetYellowAIPayload(action) {
  const prevPayload = yield select((state) =>
    selectors.makeSelectYellowAiPayload()(state),
  )

  let payload = {
    login: 'no',
    userEmail: '',
    name: '',
  }

  yield delay(200)

  if (action.payload) {
    const email = action.payload.email

    payload = {
      login: 'yes',
      userEmail: email,
      name: getCurrentUserFullName(),
    }
  }

  try {
    if (!isEqual(payload, prevPayload)) {
      window?.YellowMessengerPlugin.init({
        payload,
      })

      yield put(actions.setYellowAiPayloadSuccess(payload))
    }
  } catch (err) {}
}

function* onReloadApiForPage() {
  let currentUrl = window.location.pathname

  if (currentUrl === URL.checkoutCart) {
    yield put(checkoutActions.getCartTotals())
    yield put(getProductRecommendation1())
    yield put(getProductRecommendation2())
  }
}

function* onUpdateCTUserProfile(action) {
  const { id } = action?.payload
  const requestURL = `${config.apiUrl}/landersrestapi-clevertap/purchase`
  try {
    const res = yield call(request, requestURL, {
      method: 'GET',
    })
    const otherProfile = JSON.parse(res)
    ctUpdateUserProfile(cleanEmpty({ ...action?.payload, ...otherProfile }), id)
  } catch (err) {}
}

// Individual exports for testing
export default function* globalSaga() {
  yield takeLatest(constants.LOAD_CATEGORY_MENU_LEFT, onLoadCategoryMenu)
  yield takeLatest(constants.LOAD_SIDEADS, onLoadSideAds)
  yield takeLatest(constants.CHECK_LOCATION, onCheckLocation)
  yield takeLatest(constants.LOAD_SIDEBAR, onLoadSideBar)
  yield takeLatest(constants.LOAD_SUBMENUTOP, onLoadSubMenuTop)
  yield takeLatest(constants.GET_USER_INFO, onGetUserInfo)
  yield takeLatest(constants.UPDATE_SHIPPING_ADDRESS, onUpdateShippingAddress)
  yield takeLatest(constants.LOAD_PROVINCE, onLoadProvince)
  yield takeLatest(constants.LOAD_CITY, onLoadCity)
  yield takeLatest(constants.LOAD_BARANGAY, onLoadBarangay)
  yield takeLatest(constants.LOAD_FOOTER, onLoadFooter)
  yield takeLatest(constants.SUBSCRIBE_PRODUCT, onGetSubscribeProduct)
  yield takeLatest(constants.LOGOUT, onLogout)
  yield takeLatest(constants.LOAD_ALL_CMS, onLoadAllCms)
  yield takeLatest(constants.LOAD_GLOBAL_CONFIG, onLoadGlobalConfig)
  yield takeLatest(constants.LOAD_STORE_ADDRESSES, onLoadStoreAddresses)
  yield takeLatest(constants.UPDATE_FCM_TOKEN, onUpdateFCMToken)
  yield takeLatest(constants.DELETE_FCM_TOKEN, onDeleteFCMToken)
  yield takeLatest(constants.GET_STORE_VIEW_MAPPING, onGetStoreMapping)
  yield takeLatest(
    constants.REHYDRATE_STORE_VIEW_CODE,
    onRehydrateStoreViewCode,
  )
  yield takeLatest(constants.SET_YELLOW_AI_PAYLOAD, onSetYellowAIPayload)
  yield takeLatest(constants.RELOAD_API_OF_PAGE, onReloadApiForPage)
  yield takeLatest(constants.UPDATE_CT_USER_PROFILE, onUpdateCTUserProfile)
}
