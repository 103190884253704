import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const landersSlice = createSlice({
  name: 'globalSlice',
  initialState: {},
  reducers: {
    onSendLogger: (_, action: PayloadAction<any[]>) => {},
  },
})

export const { onSendLogger } = landersSlice.actions

export default landersSlice.reducer
