import { createListenerMiddleware } from '@reduxjs/toolkit'

import {
  onShowMayaRenew,
  setMembershipSF,
  setShowMayaRenew,
  onSubmitMayaInfoToSF,
  setShowCompleteMaya,
  onShowMayaXLandersOrCompleteModal,
  setShowMayaXLandersModal,
  setIsMayaCCOnly,
  onShowLoginWithMayaCCSuccess,
  setIsLoginWithMayaCC,
  onShowSnackBar,
  setMayaCCButtonOption,
  onShowExpireMC,
} from './slice'
import {
  hideMembershipModal,
  showMembershipModal,
} from 'containers/Modals/actions'
import membershipApi from 'api/membership'
import { getCurrentUser } from 'utils/validate'
import { showLoader, hideLoader } from 'containers/Landers/actions'

import { find } from 'lodash'
import { MEMBERSHIP_CARD_TYPES } from 'global-constant'
import dayjs from 'dayjs'

export const renewMayaListenerMiddleware = createListenerMiddleware()

renewMayaListenerMiddleware.startListening({
  actionCreator: onShowMayaRenew,
  effect: async (_, listenerApi) => {
    try {
      listenerApi.dispatch(setMembershipSF({}))
      listenerApi.dispatch(showLoader())
      const mayaCCButtonOption =
        listenerApi.getState()?.renewMayaSlice?.mayaCCButtonOption
      const currentUser = listenerApi.getState()?.rnglobal?.currentUser

      const membershipExpiryData =
        listenerApi.getState()?.renewMayaSlice?.membershipExpiryData

      let isMayaCCOnly = _.payload?.isMayaCCOnly | false

      if (mayaCCButtonOption === 'Renewal') {
        isMayaCCOnly = false
      }

      const code =
        currentUser?.extension_attributes?.membership_info?.code || null

      if (code !== null) {
        const expiredDate = membershipExpiryData?.expiry_date
        const isMembershipExpired =
          membershipExpiryData?.expiry_date?.is_expired
        const currentDate = dayjs()
        const endDate = new Date(expiredDate)

        const numberOfDaysRemaining = dayjs(endDate).diff(currentDate, 'day')
        const isMCExpired = isMembershipExpired || numberOfDaysRemaining <= 0

        let expireMcDetails = {
          type: '',
          expiryDate: '',
        }

        if (isMCExpired && isMayaCCOnly) {
          expireMcDetails.type = 'Expired'
          expireMcDetails.expiryDate = expiredDate
          listenerApi.dispatch(onShowExpireMC(expireMcDetails))
          return
        }
        if (numberOfDaysRemaining < 90 && isMayaCCOnly) {
          expireMcDetails.type = 'Near Expiry'
          expireMcDetails.expiryDate = expiredDate
          listenerApi.dispatch(onShowExpireMC(expireMcDetails))
          return
        }

        const result = await listenerApi
          .dispatch(
            membershipApi.endpoints.getMembershipInfoSF.initiate({
              code,
            }),
          )
          .unwrap()
        listenerApi.dispatch(setMembershipSF(result))

        let isAlreadyApplied = false
        if (
          (result?.landers_credit_card_applied !== '' ||
            result?.qr_used !== '') &&
          isMayaCCOnly
        ) {
          isAlreadyApplied = true
        }

        if (!isAlreadyApplied && _.payload?.isPopUpSuccess) {
          listenerApi.dispatch(
            onShowSnackBar({
              title: _.payload?.title,
              body: _.payload?.body,
            }),
          )
        }
        if (isAlreadyApplied) {
          listenerApi.dispatch(setShowCompleteMaya(true))
          return true
        }
        listenerApi.dispatch(setIsMayaCCOnly(isMayaCCOnly))
      }

      listenerApi.dispatch(
        setShowMayaRenew({
          isShowMayaRenew: true,
          selectedMembershipProducts: _.payload,
        }),
      )
      listenerApi.dispatch(hideMembershipModal())
    } catch (error) {
      if (error?.status === 404) {
        const membershipProducts =
          listenerApi.getState()?.rnmembership?.membershipProducts
        const membership = find(
          membershipProducts,
          (val) => val.name === 'PREMIUM',
        )
        listenerApi.dispatch(
          showMembershipModal({
            isRenew: true,
            isPremium:
              membership.package === MEMBERSHIP_CARD_TYPES.PREMIUM_TYPE,
            ...membership,
          }),
        )
      }
    } finally {
      listenerApi.dispatch(setMayaCCButtonOption(''))
      listenerApi.dispatch(hideLoader())
    }
  },
})

export const submitMayaInfoListenerMiddleware = createListenerMiddleware()

submitMayaInfoListenerMiddleware.startListening({
  actionCreator: onSubmitMayaInfoToSF,
  effect: async (_, listenerApi) => {
    try {
      listenerApi.dispatch(showLoader())
      const result = await listenerApi
        .dispatch(
          membershipApi.endpoints.submitMembershipInfoSF.initiate(_.payload),
        )
        .unwrap()
      if (result) {
        listenerApi.dispatch(setShowMayaRenew(false))
        listenerApi.dispatch(setIsMayaCCOnly(false))
        listenerApi.dispatch(setShowCompleteMaya(true))
      }
    } catch (error) {
    } finally {
      listenerApi.dispatch(hideLoader())
    }
  },
})

export const showMayaXLandersMiddleware = createListenerMiddleware()

showMayaXLandersMiddleware.startListening({
  actionCreator: onShowMayaXLandersOrCompleteModal,
  effect: async (_, listenerApi) => {
    try {
      listenerApi.dispatch(setMembershipSF({}))
      listenerApi.dispatch(showLoader())

      const currentUser = getCurrentUser()
      const code =
        currentUser?.extension_attributes?.membership_info?.code || null

      if (code !== null) {
        const result = await listenerApi
          .dispatch(
            membershipApi.endpoints.getMembershipInfoSF.initiate({
              code,
            }),
          )
          .unwrap()
        listenerApi.dispatch(setMembershipSF(result))
        if (
          result?.landers_credit_card_applied !== '' ||
          result?.qr_used !== ''
        ) {
          listenerApi.dispatch(setShowCompleteMaya(true))
          return true
        }
      }
      listenerApi.dispatch(setShowMayaXLandersModal(true))
    } catch (error) {
      if (error?.status === 404) {
        listenerApi.dispatch(setShowMayaXLandersModal(true))
      }
    } finally {
      listenerApi.dispatch(hideLoader())
    }
  },
})

export const signUpLoginWithMayaCCMiddleware = createListenerMiddleware()

signUpLoginWithMayaCCMiddleware.startListening({
  actionCreator: onShowLoginWithMayaCCSuccess,
  effect: async (_, listenerApi) => {
    try {
      listenerApi.dispatch(setIsLoginWithMayaCC(false))
      const currentUser = listenerApi.getState()?.rnglobal?.currentUser
      if (
        currentUser?.extension_attributes?.membership_info.type === 'BUSINESS'
      ) {
        return true
      }

      listenerApi.dispatch(
        onShowMayaRenew({
          isPopUpSuccess: true,
          isMayaCCOnly: true,
          title: _.payload?.title,
          body: _.payload?.body,
        }),
      )
    } catch (error) {
    } finally {
    }
  },
})
