import dayjs from 'dayjs'
import { LOCAL_STORAGE_NAME_PREFIX } from '../constant/Storage'

const prefixedName = (name) => `${LOCAL_STORAGE_NAME_PREFIX}${name}`

export const loadItem = (name) => {
  try {
    const item = JSON.parse(window.localStorage.getItem(prefixedName(name)))
    if (!item || item === '') {
      return undefined
    }
    return item
  } catch (err) {
    return undefined
  }
}

export const saveItem = (name, data) => {
  try {
    const item = JSON.stringify(data)
    window.localStorage.setItem(prefixedName(name), item)
    return 'Saved'
  } catch (err) {
    return undefined
  }
}

export const removeItem = (name) => {
  try {
    window.localStorage.removeItem(prefixedName(name))
    return 'Removed'
  } catch (err) {
    return err
  }
}

export const clearItems = () => {
  try {
    window.localStorage.clear()
    sessionStorage.clear()
    return 'Removed'
  } catch (err) {
    return err
  }
}

export function setBannerTime(value) {
  localStorage.setItem('smart_banner', value)
}

export function showBannerRedirect() {
  const bannerTime = localStorage.getItem('smart_banner')

  const isExpired = dayjs(bannerTime).isValid() && dayjs().isAfter(bannerTime)

  if (!bannerTime || isExpired) {
    return true
  }

  return false
}
