import { get } from 'lodash'
import { getPercent } from 'utils/helpers/getPercent'

const formatItem = (items = []) => {
  let formattedProduct = []
  if (items.length > 0) {
    formattedProduct = items.map((values) => {
      const additionalData = JSON.parse(values?.additional_data[0])
      return {
        sku_id: values?.sku,
        sku_name: values?.name,
        quantity: values?.qty_ordered,
        sku_price_regular: values?.original_price,
        sku_price_special: values?.price,
        sku_price_discount: getPercent(values?.original_price, values?.price),
        brand: additionalData?.brand,
      }
    })
  }

  return formattedProduct
}

export const getChargedPayload = (orderDetails, timeslot) => {
  const paymentMethod = orderDetails?.payment?.additional_information[0] || ''
  const hasSelectedSlot = timeslot !== null && Object.keys(timeslot).length > 0
  const isPureMC = orderDetails
    ? get(orderDetails, 'increment_id', false).startsWith('MC')
    : false
  return {
    order_number: orderDetails?.increment_id || '',
    order_subtotal: orderDetails?.base_subtotal || '',
    order_total: orderDetails?.base_grand_total || '',
    isPureMC,
    payment_method: paymentMethod,
    delivery_promise: !!hasSelectedSlot ? timeslot?.period : '',
    delivery_promise_date: !!hasSelectedSlot ? timeslot?.date : '',
    order_date: orderDetails?.created_at || '',
    Items: formatItem(orderDetails?.items),
  }
}
